import styled from 'styled-components';

export default styled.section`
	padding-top: 5rem;
	padding-bottom: 5rem;

	@media (min-width: 1024px) {
		padding-top: 10rem;
		padding-bottom: 12rem;
	}
`;
