import styled from 'styled-components';

export default styled.form`
	label {
		display: block;
		font-size: 1.4rem;
		font-weight: ${(props) => props.theme.fonts.weights.regular};
		text-transform: uppercase;
		margin-bottom: 0.5em;
	}

	input {
		&[type='text'],
		&[type='email'] {
			margin-bottom: 2rem;
		}
	}

	input,
	textarea,
	select {
		font-family: ${(props) => props.theme.fonts.sans};
		font-weight: ${(props) => props.theme.fonts.weights.regular};
		font-size: 1.6rem;
		color: ${(props) => props.theme.colors.inputText};
		border: none;
		background-color: ${(props) => props.theme.colors.inputBg};
		border-radius: 0;
		width: 100%;
		box-shadow: none;
		padding: 2em 1.5em;
	}

	select {
		padding: 0 1.5em;
		height: 4em;
	}

	/* Arrow on select element */
	[for='subject'] {
		position: relative;
	}

	[for='subject']::before {
		content: '';
		height: 0;
		width: 0;
		position: absolute;
		top: 5.5rem;
		right: 2rem;
		border-top: 10px solid ${(props) => props.theme.colors.primary};
		border-right: 5px solid transparent;
		border-left: 5px solid transparent;
		display: inline-block;
		z-index: 2;
		pointer-events: none;
	}

	textarea {
		margin-bottom: 4rem;
		resize: none;
	}

	.left-column {
		margin-bottom: 2rem;
	}

	.submit-button-container {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
		height: 4em;
	}
`;
