import React, { Component } from 'react';
import { navigate } from 'gatsby-link';

import StyledForm from '../../styles/formStyles';

function encode(data) {
	return Object.keys(data)
		.map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
		.join('&');
}

class ContactForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isValidated: false,
			isSubmitted: false,
			subject: 'Design Services',
		};
	}

	handleChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};

	handleSubmit = (e) => {
		e.preventDefault();
		const form = e.target;
		fetch('/', {
			method: 'POST',
			headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
			body: encode({
				'form-name': form.getAttribute('name'),
				...this.state,
			}),
		})
			.then(() => navigate(form.getAttribute('action')))
			.catch((error) => alert(error));
	};

	render() {
		return (
			<StyledForm
				name="Contact"
				method="post"
				action="/contact-us/thanks/"
				data-netlify="true"
				data-netlify-honeypot="bot-field"
				onSubmit={this.handleSubmit}
			>
				{/* The `form-name` hidden field is required to support form submissions without JavaScript */}
				<input type="hidden" name="form-name" value="Contact" />
				<div hidden>
					<label>
						Don’t fill this out:{' '}
						<input name="bot-field" onChange={this.handleChange} />
					</label>
				</div>
				<div className="columns">
					<div className="column left-column">
						<label htmlFor={'first-name'}>Name*</label>
						<div className="control">
							<input
								className="input"
								type={'text'}
								name={'first-name'}
								onChange={this.handleChange}
								id={'first-name'}
								required={true}
							/>
						</div>
						<label htmlFor={'email'}>Email*</label>
						<div className="control">
							<input
								className="input"
								type={'email'}
								name={'email'}
								onChange={this.handleChange}
								id={'email'}
								required={true}
							/>
						</div>
						<div className="columns">
							<div className="column">
								<label htmlFor={'phone'}>Phone</label>
								<div className="control">
									<input
										className="input"
										type={'text'}
										name={'phone'}
										onChange={this.handleChange}
										id={'phone'}
									/>
								</div>
							</div>
							<div className="column"></div>
						</div>
					</div>
					<div className="column">
						<label htmlFor={'notes'}>Message*</label>
						<div className="control">
							<textarea
								name={'notes'}
								onChange={this.handleChange}
								id={'notes'}
								required={true}
								rows="5"
							/>
						</div>
						<div className="submit-button-container">
							<button className="button button-1" type="submit">
								Submit
							</button>
						</div>
					</div>
				</div>
			</StyledForm>
		);
	}
}

export default ContactForm;
