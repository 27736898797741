import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import styled from 'styled-components';

import Layout from '../components/Layout';
import SEO from '../components/Seo';
import PreviewCompatibleImage from '../components/PreviewCompatibleImage';
import ContactForm from '../components/forms/ContactForm';

import RegularPage from '../styles/regularPage';
import TextSection from '../styles/textSectionStyle';

import mapImage from '../img/pnr-map.svg';

const StyledArticle = styled(RegularPage)`
	h2 {
		font-family: ${(props) => props.theme.fonts.sans};
		font-size: 3.2rem;
		font-weight: ${(props) => props.theme.fonts.weights.light};
		line-height: 1;
		margin-bottom: 2rem;
	}

	.info-section {
		.covid-copy {
			max-width: 950px;

			@media (max-width: 1023px) {
				margin-bottom: 3rem;
			}
		}

		.info-column {
			margin-bottom: 5rem;

			h2 {
				letter-spacing: 2px;
			}

			address {
				font-style: normal;
			}

			a {
				color: ${(props) => props.theme.colors.secondary};

				&:hover {
					color: ${(props) => props.theme.colors.primary};
					text-decoration: underline;
				}
			}

			address,
			a {
				display: block;
				line-height: 2;
			}

			h3 {
				margin-top: 4rem;
				margin-bottom: 3rem;
			}

			ul {
				margin-bottom: 3rem;
				li {
					line-height: 2;
				}
			}

			.disclaimer {
				margin-bottom: 1.5em;

				p {
					font-weight: bold;
					margin-bottom: 0.5em;
				}
			}

			.store-link {
				color: ${(props) => props.theme.colors.primary};
				font-size: 1.4rem;
				text-transform: uppercase;
				letter-spacing: 0.15em;

				&:hover {
					color: ${(props) => props.theme.colors.secondary};
				}
			}

			@media (min-width: 1024px) {
				padding-top: 8rem;
			}
		}

		.map-column {
			img {
				max-width: 260px;
				@media (max-width: 768px) {
					max-width: 100%;
					width: 100%;
				}
			}
		}
	}

	.form-heading {
		padding-bottom: 5rem;

		h2 {
			color: ${(props) => props.theme.colors.textDarker};
			text-transform: none;
			letter-spacing: normal;
			margin-bottom: 1rem;
		}

		p {
			max-width: 600px;
			margin: 0 auto;
			line-height: 2;
		}
	}

	.contact-form-section {
		padding-bottom: 15rem;
	}
`;

export const ContactUsPageTemplate = ({ title, featuredImage }) => (
	<StyledArticle>
		<section className="section">
			<div className="container">
				<div className="columns">
					<div className="column">
						<h1>{title}</h1>
					</div>
				</div>
			</div>
		</section>
		<section className="section info-section">
			<div className="container">
				{/* <div className="columns">
					<div className="column">
						<h2>A MESSAGE TO OUR CLIENTS, FRIENDS & FAMILIES</h2>
						<p className="covid-copy">
							In order to protect our community and team, we are temporarily
							closing our two retail locations in Toronto and Port Carling. Our
							teams are still working on new and existing projects off site, and
							it’s our full intention to provide service during this difficult
							time.
						</p>
					</div>
				</div> */}
				<div className="columns">
					<div className="column info-column">
						<h2>Toronto</h2>
						<address>
							275 Macpherson Ave. <br />
							Suite 103 <br />
							Toronto, ON <br />
							M4V 1A4
						</address>
						<a href="tel:+16473503500">647-350-3500</a>
						<a href="mailto:toronto@peaksandrafters.com">
							toronto@peaksandrafters.com
						</a>
						<h3>Hours</h3>
						<ul>
							<li>Monday – Friday: 9am – 5pm</li>
							<li>Saturday and Sunday: Closed</li>
						</ul>
						<div className="disclaimer"></div>
					</div>
					<div className="column info-column">
						<h2>Port Carling</h2>
						<address>
							162 Medora Street <br />
							Port Carling, ON  <br />
							P0B 1J0
						</address>
						<a href="tel:+17057656868">705-765-6868</a>
						<a href="mailto:portcarling@peaksandrafters.com">
							portcarling@peaksandrafters.com
						</a>
						<h3>Hours</h3>
						<ul>
							<li>Monday – Friday: 9am – 5pm</li>
							<li>Saturday (Aug 3): 10am – 3pm</li>
							<li>Saturday (Starting Aug 10): By Appointment</li>
							<li>Sunday: Closed</li>
						</ul>
					</div>
					<div className="column has-text-right map-column">
						<img src={mapImage} alt="" />
					</div>
				</div>
			</div>
		</section>
		<TextSection className="form-heading" id="contact">
			<div className="container">
				<div className="columns">
					<div className="column">
						<h2 className="has-text-centered">
							Contact the Peaks & Rafters Team
						</h2>
					</div>
				</div>
				<div className="columns">
					<div className="column">
						<p className="has-text-centered">
							Interested in working with us? Want more info about our stores and
							services? Fill out our contact form, we’d love to talk with you!
						</p>
					</div>
				</div>
			</div>
		</TextSection>
		<section className="section contact-form-section">
			<div className="container">
				<ContactForm />
			</div>
		</section>
		<PreviewCompatibleImage imageInfo={featuredImage} />
	</StyledArticle>
);

ContactUsPageTemplate.propTypes = {
	title: PropTypes.string,
};

const ContactUsPage = ({ data }) => {
	const { frontmatter } = data.markdownRemark;

	return (
		<Layout background="light">
			<SEO title={frontmatter.title} />
			<ContactUsPageTemplate
				title={frontmatter.title}
				featuredImage={frontmatter.featured_image}
			/>
		</Layout>
	);
};

ContactUsPage.propTypes = {
	data: PropTypes.shape({
		markdownRemark: PropTypes.shape({
			frontmatter: PropTypes.object,
		}),
	}),
};

export default ContactUsPage;

export const contactUsPageQuery = graphql`
	query ContactUsPage($id: String!) {
		markdownRemark(id: { eq: $id }) {
			frontmatter {
				title
				featured_image {
					alt
					image {
						childImageSharp {
							fluid(maxWidth: 1920) {
								...GatsbyImageSharpFluid
							}
						}
					}
				}
			}
		}
	}
`;
